import { Input } from "@chakra-ui/react";
import { DarkerTheme, LightTheme } from "../../Styles/colors";

const InputComponent = ({ input, onChange, control, reset, getValues, handleValidate }) => {
	return (
		<Input
			id={input.id}
			name={input.name}
			placeHolder={input.placeHolder}
			mb="3"
			color={DarkerTheme}
			control={control}
			onChange={(e) =>{
				e.target.value = e.target.value.substr(0, 3);
					reset({
						...getValues(),
						[input.id]: e.target.value,
					})
					handleValidate()
				}
			}
			border="none"
			background={LightTheme}
		/>
	);
};
export default InputComponent;
