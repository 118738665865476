import { LightBlue, MainGreen, MainOrange, MainPurple, widgetPrimaryColor } from "../../Styles/colors";

export const inputNames = [
	{
		name: "Origin Airport",
		id: "origin",
		required: true,
		type: "text",
	},
	{
		name: "Destination Airport",
		id: "destination",
		required: true,
		type: "text",
	},

	{
		name: "Num. Passengers",
		id: "numPassengers",
		required: false,
		type: "number",
		message: "",
	},
	{
		name: "Trip",
		id: "trip",
		required: false,
		type: "radio",
		options: ["One Way", "Round Trip"],
		message: "",
		inputColor:LightBlue,
	},
	{
		name: "Currency",
		id: "currency",
		required: false,
		type: "select",
		options: ["USD", "GBP", "EUR"],
		message: "",
		inputColor:MainGreen
	},
	{
		name: "Theme Color",
		id: "theme",
		required: false,
		type: "select",
		options: ["Green", "Purple", "Mix"],
		message: "",
		inputColor:[widgetPrimaryColor, "#7774fb", "linear-gradient(to right, #BB4B9F,#A877B5,#96A4CA,#83D0E0)"]
	},
];
