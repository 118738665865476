import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import MainPage from "./Pages/Main/Main";
import { useEffect } from "react";

function App() {
	useEffect(() => {
		if(window.Leaf) {
			window.Leaf.init("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1dWlkIjoiYTAwMzM1OTItNjYzOC00NzBmLTg5ZDMtZGI3Zjc1NjA0NjExIiwiaWQiOjk2LCJtZXJjaGFudCI6eyJpZCI6ODQsInV1aWQiOiJiZTZmZjNhMy0zYTNhLTRkNjktYWFkOC1mYzBmZDc5MDdiYzgifSwiaWF0IjoxNjg4MjMzOTUyfQ.p-VjhZei0d1w6LDR5GAAAI5y-1senZmKM7hIJq4a5B8");
		}
	},[ ])
	return (
		<ChakraProvider>
			<MainPage />
		</ChakraProvider>
	);
}

export default App;
