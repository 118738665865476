import { MainGreen, widgetButtonColor, widgetButtonTextColor, widgetMixThemeButtonColor, widgetMixThemePrimaryColor, widgetMixThemeSecondaryColor, widgetPrimaryColor, widgetPrimaryTextColor, widgetProminentColor, widgetPurpleThemeButtonColor, widgetPurpleThemePrimaryColor, widgetPurpleThemeSecondaryColor, widgetSecondaryColor } from "../Styles/colors"

export const changeTheme = (theme) => {
    if(theme === "Green") {
        window.Leaf.setTheme({
            "primaryColor":widgetPrimaryColor,
            "secondaryColor":widgetSecondaryColor,
            "buttonColor":widgetButtonColor,
            "prominentColor":widgetProminentColor,
            "primaryTextColor":widgetPrimaryTextColor,
            "buttonTextColor":widgetButtonTextColor
        })
    }
    if(theme === "Mix") {
        window.Leaf.setTheme({
            "primaryColor":widgetMixThemePrimaryColor,
            "secondaryColor":widgetMixThemeSecondaryColor,
            "buttonColor":widgetMixThemeButtonColor,
            "prominentColor":MainGreen,
            "primaryTextColor":widgetPrimaryTextColor,
            "buttonTextColor":widgetButtonTextColor
        })
    }
    if(theme === "Purple") {
        window.Leaf.setTheme({
            "primaryColor":widgetPurpleThemePrimaryColor,
            "secondaryColor":widgetPurpleThemeSecondaryColor,
            "buttonColor":widgetPurpleThemeButtonColor,
            "prominentColor":widgetProminentColor,
            "primaryTextColor":widgetPrimaryTextColor,
            "buttonTextColor":widgetButtonTextColor
        })
    }
}