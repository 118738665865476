import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	FormLabel,
	Spinner,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import RadioComponent from "../../Components/Radio/Radio";
import InputComponent from "../../Components/Input/Input";
import { inputNames } from "./MainInputs";
import { AchievementPink, AchievementPurple, Blue600, DarkerTheme, LightBlue, MainBlue, MainGreen, MainOrange, MainPink, MainPurple, MainYellow, PurpleBlue, widgetButtonTextColor, widgetPrimaryTextColor, widgetProminentColor } from "../../Styles/colors";
import {
	BoxSize,
	BreakLine,
	Flex,
	LittleBreakLine,
	Parag,
} from "../../Styles/styles";
import { useCallback, useEffect, useState } from "react";
import { fetchData } from "../../Utils/fetchData";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { useScreenSize } from "../../Utils/useScreenSize";
import { changeTheme } from "../../Utils/themeChange";

const MainPage = () => {
	const { control, handleSubmit, reset, getValues } = useForm({
		defaultValues: {
			origin: "",
			destination: "",
			numPassengers: "",
			trip: 0,
			currency:"USD",
			theme:"Green"
		},
	});
	const [disabled, setDisabled] = useState(true);
	const [showWidget, setShowWidget] = useState(false);
	const [loader, setLoader] = useState(false);
	const screenSize = useScreenSize();
	
	const validate = useCallback(() => {
		if(getValues("destination").length !== 0 && getValues("origin").length !== 0 && getValues("numPassengers").length !== 0) return true;
		return false;
	},[getValues])

	const handleSubmitForm = useCallback(() => {
		handleSubmit();
		setDisabled(false);
		setShowWidget(false);
		setLoader(true);
		changeTheme(getValues("theme"));
		setTimeout(() => {
			setLoader(false);
			setShowWidget(true);
		}, 500);
	},[handleSubmit,getValues])


	const handleValidate = useCallback(async () => {
		setDisabled(true);
		if(validate()) {
			setDisabled(false);
			handleSubmit();
		}
	}, [handleSubmit, validate]);

	useEffect(() => {
		setShowWidget(false);
	}, []);

	return (
		<Flex>
			<BoxSize
				flexSize={"2 0 10%"}
				shadow={true}
				style={{ margin: "2% 2% 0% 2%" }}
			>
				<form
					onSubmit={handleSubmit((data) => handleSubmitForm(data))}
					style={{
						padding: "1rem",
					}}
				>
					<>
						{inputNames.map((input) => (
							<>
								<FormControl id={input.name} isRequired={input.required}>
									<FormLabel
										color={DarkerTheme}
										fontSize="1.1rem"
										textAlign="left"
										pb="0"
										marginBottom="0px"
									>
										{input.name}
									</FormLabel>
									<FormHelperText mb="1" color={DarkerTheme}>
										{input.message}
									</FormHelperText>{" "}
									{input.type === "text" || input.type === "number" ? (
										<InputComponent
											input={input}
											value={getValues(input.id)}
											reset={reset}
											handleValidate={handleValidate}
											getValues={getValues}
										/>
									) : (
										<Flex>
											<BoxSize flex={"2 0 40%"} style={{ paddingLeft: "0" }}>
												<RadioComponent
													input={input}
													reset={reset}
													handleValidate={handleValidate}
													getValues={getValues}
												/>
											</BoxSize>
										</Flex>
									)}
								</FormControl>
								<LittleBreakLine />
							</>
						))}
					</>
					<Button
						isDisabled={disabled}
						w="100%"
						bg={MainPink}
						type="submit"
						color="white"
						colorScheme="pink"
						onClick={handleValidate}
					>
						Calculate My Flight
					</Button>
				</form>
			</BoxSize>
			<BoxSize
				flexSize={screenSize === "phone" || screenSize === "fullscreen" ? "100%" :"1"}
				style={{
					margin: "2% 0%",
					justifyContent: "center",
					alignItems: "center",
					display: "flex",
				}}
			>
				{screenSize === "phone" || screenSize === "fullscreen" ? (
					<IoIosArrowDown size={"5rem"} color={MainPink} />
				) : (
					<IoIosArrowForward size={"5rem"} color={MainPink} />
				)}
			</BoxSize>

			<BoxSize
				shadow={false}
				flexSize="2 0 30%"
				style={{
					margin: "2% 2%",
					justifyContent: "center",
					alignItems: "center",
					display: "flex",
				}}
			>
				{loader && <Spinner color={MainPink} size="xl" />}
				{showWidget && (
					<Parag color="black">
						<leaf-widgets-offset
							currency={getValues("currency")}
							estimation-params={JSON.stringify({
								passengers: Number(getValues("numPassengers")),
								destinations:  
								  getValues("trip") === "Round Trip" ?  [ 
									[
										{
											airport: getValues("origin"),
										},
										{
											airport: getValues("destination"),
										},
									],
									[
										{
											airport: getValues("destination"),
										},
										{
											airport: getValues("origin"),
										},
									],]
								  :[
									[
										{
											airport: getValues("origin"),
										},
										{
											airport: getValues("destination"),
										},
									],]
	
							})}
						></leaf-widgets-offset>
					</Parag>
				)}
			</BoxSize>
			
		</Flex>
	);
};

export default MainPage;
